.posts__items{
  display: flex;
  flex-direction: column;
  .posts__item{
    background: #fff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .2);
    padding: 30px 40px;
    position: relative;
    z-index: 0;
    margin-bottom: 40px;
    display: flex;
    @media (max-width: 767px) {
      padding: 20px;
      flex-direction: column;
    }
    .posts__img{
      width: 30%;
      flex: none;
      background-size: contain;
      background-position: top;
      background-repeat: no-repeat;
      margin-right: 30px;
      transition: .3s;
      @media (max-width: 767px) {
        width: 100%;
        height: 150px;
        margin-bottom: 20px;
      }
      a{
        display: block;
        height: 100%;
      }
      &:hover{
        opacity: .75;
      }
    }
    .posts__wrap{
      h4{
        font-family: 'Oswald', sans-serif;
        text-transform: uppercase;
        //color: #000;
        letter-spacing: 1px;
        position: relative;
        font-size: 25px;
        line-height: 35px;
        text-align: inherit;
        margin: 0 0 15px;
        transition: .3s;
        &:hover{
          color: #a0cc50;
        }
        a{
          text-decoration: none;
          color: inherit;
        }
      }
      .posts__description{
        margin: 0 0 15px;
        font-size: 16px;
        img{
          display: none;
        }
      }
    }
    .button{
      a{
        color: inherit;
        text-decoration: none;
      }
    }
  }
}