.editPost{
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 3px;
  &.night{
    background-color: #181818;
    .RichTextEditor__root___2QXK-,
    .MuiFormLabel-root{
      background-color: #181818;
    }
    *{
      color: #e6e6e6;
    }
    .MuiOutlinedInput-notchedOutline,
    .radio{
      border-color: #e6e6e6;
    }
    .addPost__file{
      background-color: #181818;
      border: 1px solid #e6e6e6;
    }
  }
  .editPost__file{
    display: flex;
    div{
      width: 50%;
      background-size: cover;
      background-position: center;
    }
    input{
      width: 50%;
    }
  }
  .MuiFormControl-root {
    margin: 0 0 40px;
    position: relative;
    .MuiFormHelperText-root {
      position: absolute;
      top: 100%;
      margin-left: 0;
      &::before {
        content: '\f071';
        font-family: "Font Awesome 5 Free";
        display: inline-block;
        padding-right: 3px;
        font-weight: 700;
      }
    }
  }
  button{
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.MuiDialog-root{
  z-index: 1500!important;
  .MuiDialog-paperScrollPaper{
    width: 100%;
    max-width: 100%;
    background: transparent;
    box-shadow: none;
    margin: 0;
    .MuiDialogContent-root{
      box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
      padding: 0;
    }
  }
}