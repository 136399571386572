.posts{
  display: flex;
  justify-content: space-between;
  @media (max-width: 991px) {
    flex-direction: column;
  }
  .posts__list{
    width: 70%;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  .sidebar{
    width: 25%;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  h1{
    font-family: 'Oswald', sans-serif;
    font-size: 40px;
    margin-top: 0;
  }
}
.posts__info{
  display: flex;
  font-size: 14px;
  margin-bottom: 10px;
  p{
    margin: 0 20px 0 0;
    a{
      color: inherit;
      text-decoration: none;
      transition: .3s;
      &:hover{
        color: #a0cc50;
      }
    }
    &::before{
      content: '\f007';
      font-family: "Font Awesome 5 Free";
      padding-right: 3px;
    }
    &.cat::before{
      content: '\f07c';
    }
    &.time::before{
      content: '\f017';
    }
  }
  time{
    margin-right: 20px;
    &::before{
      content: '\f073';
      font-family: "Font Awesome 5 Free";
      padding-right: 3px;
    }
  }
  span{
    &::before{
      content: '\f4ad';
      font-family: "Font Awesome 5 Free";
      padding-right: 3px;
    }
  }
}
@keyframes fadeIn{
  from{
    opacity: 0;
    transform: translateY(10px);
  }
  to{
    opacity: 1;
    transform: translateY(0);
  }
}
.fade-in{
  animation: fadeIn .3s ease-in;
}