.comments{
  margin-top: 40px;
  h2{
    font-size: 20px;
    text-align: center;
    margin-bottom: 50px;
    text-transform: uppercase;
    letter-spacing: 4px;
    line-height: 100%;
    color: #000;
    position: relative;
    margin-top: 0;
    font-family: Oswald, Arial, sans-serif;
  }
  .comments__items{
    &[data-sort='newest']{
      display: flex;
      flex-direction: column-reverse;
    }
    .comments__item{
      border: 1px solid #e5e5e5;
      margin-bottom: 15px;
      padding: 30px;
      border-radius: 5px;
      form{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .MuiFormControl-root{
          margin-bottom: 20px;
          width: 100%;

        }
        .MuiInputLabel-outlined.MuiInputLabel-shrink{
          background: #fff;
          padding: 2px 5px;
        }
      }
      h4{
        margin-top: 0;
        border-bottom: 1px solid #e5e5e5;
        position: relative;
        time{
          position: absolute;
          right: 0;
          font-weight: normal;
          font-size: 12px;
        }
      }
      p{
        margin: 0;
        font-size: 14px;
        line-height: 26px;
      }
      &[data-open="false"]{
        form{
          display: none;
        }
      }
      &[data-open="true"]{
        .comments__edit, p{
          display: none;
        }
      }
      .comments__edit{
        button:first-of-type{
          margin-right: 20px;
        }
      }
    }
    &.editing{

    }
  }
  .comments__sort{
    display: flex;
    justify-content: flex-end;
    .MuiFormControl-root{
      min-width: 100px;
      .MuiSelect-select,
      .MuiInputLabel-formControl{
        font-size: 14px;
        &:focus{
          background: none;
        }
      }
    }
  }
}