footer {
  text-align: center;
  padding: 35px 0 30px;
  margin-top: auto;
  .footer-socials {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    a{
      &[class*="face"] {
        color: #38589a;
      }
      &[class*="twit"] {
        color: #54aeef;
      }
      &[class*="insta"] {
        color: #6a443b;
      }
      &[class*="link"] {
        color: #007ab9;
      }

      &[class*="yout"] {
        color: #cc202d;
      }

      &[class*="goog"] {
        color: #dc4d41;
      }
    }
  }
  .fab {
    display: flex;
    justify-content: center;
    margin: 0 5px 10px;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    box-shadow: 0 10px 45px 0 rgba(22, 59, 98, 0.2);
    position: relative;
    transition: .3s;
    &:before {
      align-self: center;
      font-size: 18px;
      text-shadow: 0 10px 15px rgba(106, 115, 138, 0.4);
      transition: all 0.3s ease;
      position: absolute;
      width: 100%;
      height: 100%;
      line-height: 50px;
    }
    &:hover {
      box-shadow: none;
    }
  }
  .copyright {
    padding-top: 20px;
  }
}
