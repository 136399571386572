.post{
  display: flex;
  justify-content: space-between;
  @media (max-width: 991px) {
    flex-direction: column;
  }
  .sidebar{
    width: 25%;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  .post__list{
    width: 70%;
    @media (max-width: 991px) {
      width: 100%;
    }
    .post__img{
      img{
        max-width: 100%;
        height: auto;
      }
    }
    .post__info{
      margin-bottom: 15px;
    }
    .post__content{
      img{
        max-width: 100%;
        height: auto;
      }
      a{
        color: inherit;
        text-decoration: none;
        border-bottom: 1px solid #a0cc50;
        transition: .3s;
        &:hover{
          color: #a0cc50;

        }
      }
    }
  }
  .post__edit{
    margin-top: 20px;
    button:first-of-type{
      margin-right: 20px;
    }
  }
}
.confirm{
  &.MuiDialog-root{
    display: flex;
    .MuiDialog-scrollPaper{
      margin: 0 auto;
    }
    .MuiDialog-paperScrollPaper{
      background: #fff;
      box-shadow: none;
      .MuiDialogContent-root{
        padding: 30px;
        box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
        h4{
          margin-top: 0;
        }
        .confirm__answer{
          display: flex;
          justify-content: center;
          button{
            margin: 0 10px;
          }
        }
      }
    }
  }
}
