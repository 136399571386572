body{
  font-family: 'Prompt', serif;
  margin: 0;
  .app-container{
    //background-color: #e6e6e6;
    padding-top: 100px;
    &.night{
      background: #181818;
      .header__wrapper,
      .MuiFormLabel-root,
      .RichTextEditor__root___2QXK-{
        background-color: #181818!important;
      }
      .header{
        .button__switcher {
          left: calc(100% - 24px);
        }
        .button__day {
          filter: brightness(1.4);
        }
      }
      *{
        color: #e6e6e6;
      }
      .fab{
        background-color: #e6e6e6;
      }
      .posts__item{
        background-color: #181818;
        box-shadow: none;
        border: 1px solid #393939;
      }
      .MuiOutlinedInput-notchedOutline,
      .radio{
        border-color: #e6e6e6;
      }
      .addPost__file{
        background-color: #181818;
        border: 1px solid #e6e6e6;
      }
    }
    &:not(.night){
      .header{
        .button__night {
          filter: brightness(2.3);
        }
      }
    }
    &>.container{
      display: flex;
      flex-direction: column;
      min-height: calc(100vh - 100px);
    }
  }
  .container {
    max-width: 1170px;
    margin: 0 auto;
    padding: 0 30px;
    width: 100%;
    box-sizing: border-box;
    @media (max-width: 1200px){
      max-width: 970px;
      width: auto;
    }
    @media (max-width: 768px) {
      max-width: 100%;
    }

  }
  .MuiFormControl-root {
    margin: 0 0 40px;
    position: relative;
    .MuiFormHelperText-root{
      position: absolute;
      top: 100%;
      margin-left: 0;
      &::before{
        content: '\f071';
        font-family: "Font Awesome 5 Free";
        display: inline-block;
        padding-right: 3px;
        font-weight:700;
      }
    }
  }
  .MuiFormLabel-root,
  .MuiInputBase-root,
  .MuiMenuItem-root,
  .RichTextEditor__root___2QXK-{
    font-family: 'Prompt', serif;
  }
  .MuiFormLabel-root{
    background: #fff;
    padding: 0 3px;
  }
  .MuiSelect-select:focus{
    background-color: #fff;
  }
  .MuiFormHelperText-root.Mui-error{
    color: #f44336;
  }
  .trigger__wrap{
    .trigger{
      .MuiFormLabel-root{
        &.Mui-focused{
          color: #3f51b5;
        }
      }
      .MuiInputBase-root{
        &.Mui-error{
          color: rgba(0, 0, 0, 0.54);
        }
        &.Mui-focused{
          .MuiOutlinedInput-notchedOutline{
            border-color: #3f51b5;
          }
        }
      }
      .MuiFormHelperText-root{
        display: none;
      }
    }
  }
  .RichTextEditor__root___2QXK-{
    margin-bottom: 30px;
    .public-DraftEditor-content{
      min-height: 200px;
    }
  }
}
.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #f44336!important;
}