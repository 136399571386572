.grid-gallery {
  position: relative;
  box-sizing: border-box;
  display: grid;
  grid-auto-rows: 200px;
  grid-gap: 0.5rem;
  grid-auto-flow: dense;

  grid-template-columns: repeat(4, 1fr);
  .destination {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
    transform: translate3d(0, 0, 0);
    background-size: cover;
    background-position: center;

    &:nth-of-type(10n + 2){
      grid-column: span 2;
    }
    &:nth-of-type(10n + 4),
    &:nth-of-type(10n + 9),
    &:nth-of-type(10n + 10){
      grid-row: span 2;
    }
    &:nth-of-type(10n + 5),
    &:nth-of-type(10n + 8){
      grid-column: span 2;
      grid-row: span 2;
    }

    .img &.selected {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      z-index: 3;
      background-color: #0000008c;

      .overlay {
        opacity: 1;
      }

      .img {
        margin: 2rem;
        min-height: 400px;
        min-width: 400px;
        height: 70%;
        width: 70%;

        .close {
          top: 10%;
          left: 51%;
          position: absolute;
          content: "\00d7";
          width: 32px;
          height: 32px;
          z-index: 5;
          cursor: pointer;

          &:before,
          &:after {
            position: absolute;
            content: " ";
            height: 33px;
            width: 2px;
            left: 50%;
            background-color: #ececec;
          }

          &:before {
            transform: rotate(45deg);
          }

          &:after {
            transform: rotate(-45deg);
          }
        }
      }
    }

    &:hover:not(.selected) {
      .overlay {
        opacity: 1;
        box-shadow: 0 0 6px 3px rgb(14, 14, 14);
        border-radius: 3px;

        .btn {
          display: block;
        }
      }

      .img {
        transform: scale(1.5);
      }
    }

    @media (min-width: 790px) {
      &.vertical {
        grid-row: span 2;
      }

      &.horizontal {
        grid-column: span 2;
      }

      &.big {
        grid-column: span 2;
        grid-row: span 2;
      }
    }

    .img {
      height: 100%;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      transition: all 1s linear;
    }

    .overlay {
      opacity: 0;
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      background: #0000009c;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: opacity 0.5s;

      .btn {
        cursor: pointer;
        display: none;
        padding: 0.5rem 1rem;
        line-height: 1rem;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 2px;
        background-color: transparent;

        &:hover {
          background-color: #fff;
          color: #000;
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
