.categories{
  a{
    text-transform: capitalize;
    color: inherit;
    text-decoration: none;
    border-bottom: 1px solid #a0cc50;
    transition: .3s;
    &.active,
    &:hover{
      color: #a0cc50!important;
    }
  }
}